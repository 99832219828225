<template>
  <div class="maintenance main-block">
    <div class="main-block__head">
      <h1 class="main-block__title">{{ title }}</h1>
      <button class="transport-info__box-icon bg-inherit">
        <b-icon
          icon="pencil-square"
          aria-hidden="true"
          variant="secondary"
        />
      </button>   
    </div>
    <ul v-if="allCarsData" class="maintenance__items main-block__box">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="maintenance__item flex"
      >
        <img class="maintenance__img" :src="typeImage(item.type ?? '')" alt="" />
        <div class="maintenance__box flex">
          <div class="maintenance__id">
            <p class="maintenance__name">
              #{{ item.id }}
            </p>
            <label class="maintenance__text">{{ item?.purchase_field.purchase_category.category ?? 0 }}</label>
          </div>
          <div class="maintenance__info">
            <p class="maintenance__name">
              #{{ item?.purchase_field.name }}
            </p>
            <label class="maintenance__text">Объем: {{ item?.quantity ?? 0 }} Л</label>
            <b-icon
                class="maintenance__info-icon"
                icon="circle-fill"
                font-scale="0.3"
                style="color: #707070"
            />
            <label class="maintenance__text">Цена: {{ item?.amount ?? 0 }} ₸</label>
            
          </div>
          <div class="maintenance__date-box">
            <p class="maintenance__text">
              {{ time(item.start_date) }}
            </p>
            <label class="maintenance__date">
              {{ date(item.start_date) }}
            </label>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  computed: {
    title() {
      if (this.table === 'history') {
        return 'История обслуживания'
      }

      return 'Плановое обслуживание'
    },
    items() {
      if (this.table === 'history') {
        return this.allCarsData.maintenances
      }

      return this.allCarsData.maintenances_asd
    }
  },
  props: {
    allCarsData: {
      type: Object,
      default: () => {},
    },
    table: {
      type: String,
      default: () => 'planned'
    }
  },
  methods: {
    typeImage(type){

      if (this.table === 'history')
        return require('@/assets/svg/service_history.svg')

      if (type === '')
        return require('@/assets/svg/coolant_replace.svg')

      return require('@/assets/svg/' + type+ '_replace.svg')
    },
    time(x) {
      if(!x)
        return '-'
      return new Date(x).toLocaleTimeString('ru-RU', {hour: '2-digit',minute: '2-digit'})
    },
    date(x) {
      if(!x)
        return '-'
      return new Date(x).toLocaleDateString('ru-RU', { day:"numeric", month:"long"})
    },
  }
};
</script>

<style scoped>
.maintenance__img {
  width: 15%;
  max-width: 30px;
  height: 30px;
  /* background: #83b1f4; */
  border-radius: 5px;
  margin: 5px 10px 0 0;
}
.maintenance__box {
  width: 93%;
  padding-top: 15px;
}
.maintenance__item:not(:last-child) .maintenance__box {
  border-bottom: 1px solid #f2f2f8;
  padding-bottom: 9px;
}
.maintenance__id {
  width: 25%;
}
.maintenance__info {
  width: 52%;
  height: 30px;
}
.maintenance__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 2px;
}
.maintenance__text {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #acacac;
  margin: 0;
}
.maintenance__info-icon {
  margin: 2px 5px;
}
.maintenance__date-box {
  width: 15%;
  text-align: right;
}
.maintenance__date {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  margin: 0;
}
.bg-inherit {
  background: inherit;
}
</style>
